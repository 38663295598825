import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Warrick Corfe-Tan | Software Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my web portfolio!', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Warrick',
  subtitle: "I'm a Fullstack Software Developer",
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'DSC_5341.jpg',
  paragraphOne:
    'I’m a software engineer with over seven years of experience specializing in full-stack development, cloud infrastructure, and API-driven systems. I have strong skills in JavaScript, Python, C#, and React, along with experience using AWS and modern frameworks like Angular and Vue.',
  paragraphTwo:
    'At OVO Energy, I developed backend systems with AWS, GraphQL, and Apollo, and automated workflows with Terraform. I also worked at Banxa, where I built reusable frontend components and contributed to architectural decisions in a fast-paced environment.',
  paragraphThree:
    'I have extensive experience with serverless architectures and cloud tools like AWS Lambda and Docker. I’m currently traveling indefinitely and actively seeking fully remote opportunities to continue building high-quality products from anywhere.',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'research_hub_screenshot.png',
    title: 'The Research Hub',
    info:
      "The Research Hub is the University of Auckland's full-stack web application that catalogs and encompasses all phases of postgraduate research. The Research Hub was approved for additional funding in March 2020 which involved a complete overhaul of the project.",
    info2:
      "The front end improvements include a redesign to better showcase our newly designed content structure. The backend improvements include a complete migration from on-premises hosting to a completely serverless infrastructure hosted in Amazon Web Services (AWS) and Contentful's content management system. My role in this project spanned all sections of the project's stack. I developed multiple AWS microservices within AWS to replace functionality of the on-premises server as well as add new functionality such as automated integration testing. I developed our Angular frontend features to render our content data. I also developed frontend applications using ReactJS to serve as user interface extensions for Contentful to improve content creation experience.",
    url: 'https://research-hub.sandbox.amazon.auckland.ac.nz/',
    repo: 'https://github.com/uoa-eresearch/hub-stack', // if no repo, the button will not show up
    iframe: 'https://research-hub.auckland.ac.nz/',
  },
  {
    id: nanoid(),
    img: 'edna_screenshot.png',
    title: 'eDNA Virtual Hub',
    info:
      'This project was designed as an open-source visualization and analysis tool for ecological diversty across New Zealand.',
    info2:
      'I designed and developed both the frontend visualization and user interface and backend data ingestion pipeline and taxonomic organism relational database. This involved created a backend using Python, Django, and PostgreSQL. The front end technology used consisted mainly of Vanilla JS, Leaflet, and D3 for visualization.',
    url: 'https://uoa-eresearch.github.io/edna/',
    repo: 'https://github.com/uoa-eresearch/edna', // if no repo, the button will not show up
    iframe: 'https://uoa-eresearch.github.io/edna/',
  },
  {
    id: nanoid(),
    img: 'roonka_screenshot.jpeg',
    title: 'Roonka Aboriginal Burial Reconstruction',
    info:
      'The Roonka Aboriginal Burial Site was measured in the early 1950s with very detailed recordings of the site containing dozens of underground cavities and hundreds of artifacts. This project was funded by the Marsden Grant to bring more insight into the behaviors of aboriginal burials. Due to the fidelity of the excavation, we were able to procedurally generate the site by using shapefiles digitized records of the excavations. Once the site was generated, we were able to perform further spatial analysis within the burial site.',
    info2:
      "For this project, I created a suite of executable Python scripts to automate heavily repeated tasks. This involved creating hundreds of 3D models from point cloud data in large batches and identify artifacts that were contained within those models by finding intersections in the geometry. I used Blender's API to aid in my development. My final task was to categorize the artifacts and enrich the original shapefile data for use within ArcGIS.",
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'mytardis_screenshot.png',
    title: 'MyTardis Data Repository',
    info:
      'This project was designed as a repository and search interface for hosting data from numerous sources of advanced research instrumentation within the University of Auckland. Instrumentation such as mass spectrometry machinery and genomic sequencing data is searchable using instrument-specific data properties through a single unified search interface.',
    info2:
      'I contributed designed and developed the front-end aspects of the project alongside my colleague. We overcame the challenge of creating a flexible search interface by co-hosting a rapid Google design sprint with stakeholders. We then designed and developed the front-end using the resulting information.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Peroxiredoxin Fishtank',
    info:
      "This project was designed to simulate the interactions of peroxiredoxin-3 molecules within the human body and how they reduce free radical damage. This project was showcased at the Te Papa Museum in Wellington and used by New Zealand's Prime Minister Jacinda Ardern.",
    info2:
      'I developed multiple features for this project such as haptic feedback, and multiple optimisations focused towards interactivity and immersion.',
    url: '', // https://github.com/UoA-eResearch/prx_fishtank
    repo: '', // if no repo, the button will not show up
    video: 'https://www.eresearch.auckland.ac.nz/files/2018/04/h264_540-1lq0ut8.mp4',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'warrickct@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/warrick-corfe-tan',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/warrickct',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
